import { makeAutoObservable, reaction } from "mobx";
import { Location } from "react-router-dom";
import { APP_ROUTES, RootContextWrapper } from "./RootContext";

const BG_PRIMARY = "bg-gold-yellow-500";
const BG_WHITE = "bg-white";

type LayoutPage = {
  renderPageStaticBgImages: boolean;
  outletColor: string;
};

const defaulLayoutPage: LayoutPage = {
  renderPageStaticBgImages: false,
  outletColor: BG_WHITE,
};

type MenuItem = {
  name: string;
  route: string;
  active: boolean;
  submenuItems: MenuItem[];
};

class ThemeStore {
  private _layoutPage: LayoutPage = defaulLayoutPage;
  locationRef: Location | undefined;
  mobileMenuOpen: boolean = false;
  
  ROUTES: APP_ROUTES = {};
  /**
   *
   */
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    reaction(
      () => this.locationRef?.key,
      () => {
        if (this.locationRef) this.updateRenderPageStatus(this.locationRef);
      }
    );
  }
  private updateRenderPageStatus(location: Location) {
    if (
      location.pathname === this.ROUTES.LOGIN_ROUTE ||
      location.pathname === this.ROUTES.SIGNUP_ROUTE
    ) {
      this._layoutPage = {
        ...defaulLayoutPage,
        renderPageStaticBgImages: true,
        outletColor: BG_PRIMARY,
      };
    } else {
      this._layoutPage = defaulLayoutPage;
    }

    if (
      location.pathname === this.ROUTES.GIFT_ROUTE){
        this._layoutPage = {
          ...defaulLayoutPage,
          renderPageStaticBgImages: false,
          outletColor: BG_PRIMARY,
        };
      }
  }

  private getMenuItems():MenuItem[]{
    return [
      {
        active: false,
        route: this.ROUTES.SUCCESSPATH_ROUTE,
        name: "Olimpiyat Yolculuğu",
        submenuItems:[]
      },
      {
        active: false,
        route: this.ROUTES.CONTENTS_ROUTE,
        name: "İçerikler",
        submenuItems: [
          {
            active: false,
            route: this.ROUTES.SURVEYS_ROUTE,
            name: "Anketler",
            submenuItems:[]
          },
          {
            active: false,
            route: this.ROUTES.CONTENTS_ROUTE,
            name: "Harekete Geç",
            submenuItems:[]
          },
          {
            active: false,
            route: this.ROUTES.LEADERBOARD_ROUTE,
            name: "Lider Tablosu",
            submenuItems:[]
          },
          {
            active: false,
            route: this.ROUTES.ASKATHLETS_ROUTE,
            name: "Sporculara Sor",
            submenuItems:[]
          },
        ],
      },
      { active: false, route: this.ROUTES.DUTY_ROUTE, name: "Görevler",submenuItems:[] },
      { active: false, route: this.ROUTES.GIFT_ROUTE, name: "Ödüller",submenuItems:[] },
    ];
  }

  private setActiveMenu(menuItems: MenuItem[]) {
    let currentPath = this.locationRef?.pathname ?? "N/A";
    menuItems.forEach((menu) => {
      menu.active =
        menu.route === currentPath ||
        (menu?.submenuItems?.filter((a) => a.route === currentPath).length ??
          0) > 0;
    });
  }

  get layoutPage():LayoutPage{
    return {
      ...this._layoutPage,
      renderPageStaticBgImages: this._layoutPage.renderPageStaticBgImages && !this.mobileMenuOpen
    }
  }

  get mobileMenuItems(): MenuItem[] {
    let data:MenuItem[] = this.getMenuItems();

    this.setActiveMenu(data);
    return data;
  }

  get menuItems(): MenuItem[] {
    let data:MenuItem[] = this.getMenuItems();;
    this.setActiveMenu(data);
    return data;
  }

  setRoutes(routes: APP_ROUTES) {
    this.ROUTES = routes;
  }

  setMobileMenuState(state: boolean) {
    this.mobileMenuOpen = state;
  }
  init(location: Location) {
    this.locationRef = location;
    // this.setActiveMenuItem(location);
    this.updateRenderPageStatus(location);
  }
}

export default ThemeStore;
