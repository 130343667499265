import * as React from "react";

let Footer: React.FC<{}> = (props) => {
  return (
    <footer >
      <div className="container relative mx-auto p-4 flex-none max-w-7xl">
        <div
          className="flex-row items-center 
                      space-y-4 
                      md:flex md:h-20 md:flex-row md:justify-between
                      md:space-y-0
                    "
        >
          {/* Logo */}
          <div className="flex justify-center">
            <img className="w-36 sm:w-56" src="/assets/logo.png" alt="logo" />
          </div>

          {/* Links */}
          <div className="flex flex-col items-center md:flex-row md:space-x-3.5">
            <a href="/" className="inline-block text-primary">
              <p> © {new Date().getFullYear()} P&G Olympics</p>
            </a>
            <a href="#privacy-policy" className="inline-block text-primary">
              <p>Privacy Policy</p>
            </a>
            <a href="#termservice" className="inline-block text-primary">
              <p>Term of Service</p>
            </a>
          </div>

          {/* Social Media */}
          <div className="flex flex-row justify-center space-x-2">
            <a href="#twitter">
              <svg className="h-8 w-8">
                <image xlinkHref="/assets/twitter.svg" />
              </svg>
            </a>
            <a href="#instagram">
              <svg className="h-8 w-8">
                <image xlinkHref="/assets/instagram.svg" />
              </svg>
            </a>
            <a href="#facebook">
              <svg className="h-8 w-8">
                <image xlinkHref="/assets/facebook.svg" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
