import { action } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Link } from "react-router-dom";
import RootContext from "../../store/RootContext";

let Header: React.FC<{}> = (props) => {
  const { mobileMenuOpen, setMobileMenuState, menuItems } =
    React.useContext(RootContext).themeStore;
  const openMenu = React.useCallback(
    action(() => {
      console.log("Log111");
      setMobileMenuState(true);
    }),
    []
  );
  return (
    <>
      {!mobileMenuOpen && (
        <header className="z-10 flex-none bg-gold-yellow-500">
          <div className="container mx-auto min-w-max max-w-7xl flex-nowrap p-8 pt-4 pb-4">
            <div className="flex h-20  flex-row items-center justify-between">
              {/* Mobile Menu */}
              <div className="pt-2 md:hidden">
                <svg className="h-8 w-8" onClick={openMenu}>
                  <image
                    className="h-full w-full"
                    xlinkHref="/assets/humbergericon.svg"
                  />
                </svg>
              </div>
              {/* Logo */}
              <Link to={"/"}>
                <img
                  className="w-36 sm:w-56 "
                  src="/assets/logoweb.webp"
                  alt="logo"
                />
              </Link>
              {/* Menu items*/}
              <div className="menu float-right hidden items-center justify-between md:flex md:flex-row lg:pl-4">
                {menuItems &&
                  menuItems.length > 0 &&
                  menuItems.map((menu) => (
                    <div className="group flex flex-col" key={`${menu.name}-${menu.route}`}>
                      {menu.route && (
                        <Link
                          to={menu.route}
                          className=" flex flex-col items-center justify-center"
                        >
                          <p>{menu.name}</p>
                        </Link>
                      )}

                      {menu.submenuItems && menu.submenuItems.length > 0 && (
                        <div className="invisible relative -left-[50%] space-y-4 place-self-start bg-gold-yellow-500 group-hover:visible">
                          <div className="absolute flex flex-col items-center overflow-y-visible border-[1px] border-secondary bg-[#faf7f4] text-center">
                            {menu.submenuItems?.map((subMenu) => (
                              subMenu.route &&
                              <Link
                                key={`${subMenu.name}-${subMenu.route}`}
                                className="w-[152px] p-2"
                                to={subMenu.route}
                              >
                                {subMenu.name}
                              </Link>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>

              {/* Actions Web */}
              <div className="hidden items-center space-x-5 md:flex">
                <Link to="/user/signup">
                  <button className="btn-outline-d">Üye Ol</button>
                </Link>
                <Link to="/user/login">
                  <button className="btn-primary-d">Giriş Yap</button>
                </Link>
              </div>
              {/* Actions Mobile*/}
              <div className="w-8 space-y-2 md:hidden">
                <svg className="h-8 w-8">
                  <image xlinkHref="/assets/avatarcircle.svg" />
                </svg>
                <svg className="h-8 w-8">
                  <image xlinkHref="/assets/awardcircle.svg" />
                </svg>
              </div>
            </div>
          </div>
        </header>
      )}
    </>
  );
};

export default observer(Header);
