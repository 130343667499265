import React from "react";
import ThemeStore from "./ThemeStore";


type APP_ROUTES = { [k: string]: string };
class RootContextWrapper{
  themeStore:ThemeStore;
  constructor() {
    console.log('Store init');
    this.themeStore = new ThemeStore();
    
  }
}

const RootContext =  React.createContext<RootContextWrapper>(new RootContextWrapper());


export default RootContext;

export type {APP_ROUTES,RootContextWrapper};