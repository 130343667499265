import { observer } from "mobx-react-lite";
import * as React from "react";
import { Outlet } from "react-router-dom";
import RootContext from "../../store/RootContext";
import Footer from "./Footer";
import Header from "./Header";
import MobileMenu from "./MobileMenu";

const PageLayout:React.FC<{children:React.ReactNode}> = observer((props)=>{
  const { layoutPage,mobileMenuOpen } = React.useContext(RootContext).themeStore;
  return (
    <div className="flex min-h-screen flex-col">
      <Header></Header>
      <main className={`relative flex-grow ${layoutPage.outletColor} overflow-x-hidden overflow-y-visible`}>
        {!mobileMenuOpen && props.children}
        {mobileMenuOpen && <MobileMenu/>}
        {layoutPage.renderPageStaticBgImages && (
          <>
            {/* Absolute Images Group*/}
            <div className="absolute left-0 bottom-0">
              <img
                src="/assets/group-2web.webp"
                alt="olympics"
                width={"1250px"}
                height={"808px"}
                className="w-72 md:w-full md:max-w-xl"
              />
            </div>
            {/* Absolute Images 2024*/}
            <div className="absolute right-0 bottom-0">
              <img
                src="/assets/2024web.webp"
                alt="2024"
                width={"428px"}
                height={"416px"}
                className="w-32 p-4 md:w-72 md:p-10"
              />
            </div>
          </>
        )}
      </main>
      <Footer></Footer>
    </div>
  );
})

const PageRouteLayout: React.FC = () => {
  return (
    <PageLayout><Outlet/></PageLayout>
  );
};



export default observer(PageRouteLayout);

export {PageLayout};
