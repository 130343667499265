import React, { useContext, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoute from "./Routes";
import RootContext from "./store/RootContext";

// replace console.* for disable log on production
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

let App: React.FC<{}> = (props) => {
  const context = useContext(RootContext);
  useEffect(() => {
    console.log("Application init");
    
  }, []);
  return (
    <RootContext.Provider value={context}>
      <AppRoute></AppRoute>
    </RootContext.Provider>
  );
};
const AppWithRouter = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
export default AppWithRouter;
