import React from "react";
import { PageLayout } from "./PageLayout";

const LoadingPage = () => {
  return (
    <PageLayout>
      <div className="h-screen w-full bg-gold-yellow-500 ">
        <div className="h-full container mx-auto flex flex-col justify-center -m-48">
          <h2 className="text-center">Loading...</h2>
        </div>
      </div>
    </PageLayout>
  );
};

export default LoadingPage;
