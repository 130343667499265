import { action } from "mobx";
import React, { Suspense, useContext, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import LoadingPage from "./pages/layout/LoadingPage";
import PageRouteLayout from "./pages/layout/PageLayout";

import RootContext from "./store/RootContext";

const SIGNUP_ROUTE_SUFFIX = "signup";
const LOGIN_ROUTE_SUFFIX = "login";
const USER_ROUTE_PREFIX = "/user/";

const ROUTES: { [k: string]: string } = {
  SIGNUP_ROUTE: `${USER_ROUTE_PREFIX}${SIGNUP_ROUTE_SUFFIX}`,
  LOGIN_ROUTE: `${USER_ROUTE_PREFIX}${LOGIN_ROUTE_SUFFIX}`,
  WELCOME_ROUTE: `/`,
  ASKATHLETS_ROUTE: `/ask-athlets/`,
  SURVEYS_ROUTE: `/surveys/`,
  CONTENTS_ROUTE: `/contents/`,
  DUTY_ROUTE: `/duties/`,
  SUCCESSPATH_ROUTE: `/success-path/`,
  TEST_ROUTE: `/test-page/`,
  GIFT_ROUTE: `/gifts/`,
  LEADERBOARD_ROUTE: `/leader-board/`,
};

const LoginPage = React.lazy(() => import("./pages/public/LoginPage"));
const AskAtlets = React.lazy(() => import("./pages/authorized/AskAtlets"));
const Surveys = React.lazy(() => import("./pages/authorized/Surveys"));
const Contents = React.lazy(() => import("./pages/authorized/Contents"));
const Duties = React.lazy(() => import("./pages/authorized/Duties"));
const LeaderBoard = React.lazy(() => import("./pages/authorized/LeaderBoard"));
const Gifts = React.lazy(() => import("./pages/authorized/Gifts"));
const Test = React.lazy(() => import("./pages/public/Test"));
const SignupPage = React.lazy(() => import("./pages/public/SignupPage"));
const WelcomePage = React.lazy(() => import("./pages/public/WelcomePage"));

const AppRoute = () => {
  const context = useContext(RootContext);

  const location = useLocation();
  useEffect(
    action(() => {
      console.log("Route change"); //
      context.themeStore.init(location);
      context.themeStore.setRoutes(ROUTES);
    }),
    [location, ROUTES]
  );
  return (
    <Suspense fallback={<LoadingPage></LoadingPage>}>
      <Routes>
        <Route path="/" element={<PageRouteLayout />}>
            <Route path="/" element={<WelcomePage />} />
            <Route path={ROUTES.ASKATHLETS_ROUTE} element={<AskAtlets />} />
            <Route path={ROUTES.SURVEYS_ROUTE} element={<Surveys />} />
            <Route path={ROUTES.CONTENTS_ROUTE} element={<Contents />} />
            <Route path={ROUTES.DUTY_ROUTE} element={<Duties />} />
            <Route path={ROUTES.SUCCESSPATH_ROUTE} element={<WelcomePage />} />
            <Route path={ROUTES.GIFT_ROUTE} element={<Gifts />} />
            <Route path={ROUTES.LEADERBOARD_ROUTE} element={<LeaderBoard />} />
            <Route path={ROUTES.TEST_ROUTE} element={<Test />} />
            <Route path={USER_ROUTE_PREFIX}>
              <Route path={SIGNUP_ROUTE_SUFFIX} element={<SignupPage />} />
              <Route path={LOGIN_ROUTE_SUFFIX} element={<LoginPage />} />
            </Route>
        </Route>
      </Routes>
    </Suspense>
  );
};

export default AppRoute;
