import { action } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import RootContext from "../../store/RootContext";

const MenuCircle = () => {
  return (
    <svg className=" absolute -left-6 top-0.5 h-4 w-4 fill-white">
      <circle stroke="none" cx="10" cy="10" r="5" strokeWidth="0" fill="" />
    </svg>
  );
};

const MobileMenu = () => {
  const { setMobileMenuState, mobileMenuItems } =
    useContext(RootContext).themeStore;

  return (
    <div className="flex min-h-screen flex-col gap-y-4 bg-primary bg-mobile-menu-background bg-auto bg-right-bottom bg-no-repeat p-8 pt-12 font-bold text-white">
      <div className="w-full">
        <a
          href={"#"}
          className="h-8 w-8 border-2 border-primary "
          onClick={action(() => setMobileMenuState(false))}
        >
          X
        </a>
      </div>
      <div className="flex h-full w-full flex-row p-6 pl-5 pt-0 ">
        {/* Menu Container */}
        <div className="flex h-full w-full flex-col gap-y-6 ">
          {mobileMenuItems &&
            mobileMenuItems.map((menu) => (
              <div key={`${menu.route}-${menu.name}`} className="flex flex-col space-y-4">
                {menu.route && (
                  <Link to={menu.route} className="relative">
                    {menu.active && <MenuCircle></MenuCircle>}
                    <h1>{menu?.name}</h1>
                  </Link>
                )}
                {menu?.submenuItems && menu?.submenuItems.length > 0 && (
                  <div className="flex flex-col space-y-4 p-2 pl-5 font-medium">
                    {menu?.submenuItems?.map(
                      (subMenu) =>
                        subMenu.route && (
                          <Link
                            key={`${subMenu.route}-${subMenu.name}`}
                            to={subMenu.route}
                          >
                            <h2>{subMenu?.name}</h2>
                          </Link>
                        )
                    )}
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default observer(MobileMenu);
